import React from 'react'

import { Element } from './index.styled'

const Button = ({ white = false, title, ...props }) => {
  return (
    <Element
      className={white ? 'white' : ''}
      onMouseOver={(e) => {
        e.currentTarget.classList.remove('mouseOut')
        e.currentTarget.classList.add('mouseOver')
      }}
      onMouseOut={(e) => {
        e.currentTarget.classList.remove('mouseOver')
        e.currentTarget.classList.add('mouseOut')
      }}
      {...props}
    >
      {title}
    </Element>
  )
}

export default Button
