import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Transition, TransitionGroup } from 'react-transition-group'
import { gsap, ScrollSmoother, ScrollTrigger } from 'helpers/gsap'
import Header from 'components/header'
import Footer from 'components/footer'
import HomeIntroAnimation from '../ui/home-intro-animation'
import NavigationPrevNext from '../navigation-prev-next'
import FullGallery from '../full-gallery'
import useWindowSize from '../../helpers/use-window-size'
import 'helpers/fonts.css'
import GlobalStyle from 'helpers/global-styles'
import {
  LayoutWrapper,
  PageWrapper,
  ContentWrapper,
  MobileBrocureLink
} from './index.styled'
import PdfButton from '../ui/pdf-button'
import PdfPopup from '../ui/pdf-popup'

const LayoutContent = ({ children, location }) => {
  return (
    <TransitionGroup component={null}>
      <Transition key={location.pathname} timeout={{ enter: 1000, exit: 1000 }}>
        {(status) => (
          <div className={`page ${status}`}>
            <LayoutWrapper>{children}</LayoutWrapper>
          </div>
        )}
      </Transition>
    </TransitionGroup>
  )
}

const Layout = ({ children, location }) => {
  const refWrapper = React.useRef(null)
  const { isMobile } = useWindowSize()
  const [isFirstLoad, setIsFirstLoad] = useState(true)
  const [openPopupPdf, setOpenPopupPdf] = React.useState(false)

  let scrollerSmoother

  React.useLayoutEffect(() => {
    const gsapContext = gsap.context(() => {
      scrollerSmoother = ScrollSmoother.create({
        content: '.home-content',
        wrapper: '.home-wrapper',
        smooth: 2,
        effects: true
      })
      const mediaQueryAnimation = gsap.matchMedia()
      mediaQueryAnimation.add('(min-width: 769px)', () => {
        ScrollTrigger.create({
          trigger: '.last-section',
          pinSpacing: false,
          pin: true,
          start: 'top top',
          end: '+=100%',
          anticipatePin: 1
        })

        const headingSections = gsap.utils.toArray('.heading-section')
        headingSections.forEach((section) => {
          const heading = section.querySelector('.heading')
          const headingAnimation = gsap.timeline().fromTo(
            heading,
            { opacity: 0, y: 50, duration: 1.5 },
            {
              opacity: 1,
              y: 0,
              duration: 1.5
            },
            '+=.1'
          )
          ScrollTrigger.create({
            trigger: section,
            start: 'top center',
            scrub: false,
            // markers: true,
            animation: headingAnimation,
            toggleActions: 'play none none reverse'
          })
        })
        // parallax
        gsap.utils.toArray('.parallax').forEach(function (container) {
          container.style.transform = 'translateY(-100%)'
          if (container.previousElementSibling) { container.previousElementSibling.style.zIndex = '2' }
          if (container.nextElementSibling) { container.nextElementSibling.style.zIndex = '2' }

          ScrollTrigger.create({
            trigger: container.previousElementSibling,
            pin: container,
            pinSpacing: false,
            end: '+=200%',
            // end: '+=739px',

            start: 'bottom bottom'
          })
        })
      })

      ScrollTrigger.normalizeScroll({ allowNestedScroll: true })
    }, refWrapper)

    return () => gsapContext.revert()
  }, [children])

  useEffect(() => {
    if (scrollerSmoother && location.hash) {
      setTimeout(() => {
        const height =
          document.querySelector('#header').clientHeight ||
          document.querySelector('#headerMob #menu').clientHeight ||
          0

        gsap.to(scrollerSmoother, {
          scrollTop:
            document.querySelector(`${location.hash}-section`)?.offsetTop -
            height,
          duration: 2
        })
      }, 1000)
    }
  }, [scrollerSmoother, location.hash])

  return (
    <div className='home-wrapper' ref={refWrapper}>
      {isMobile && (
        <>
          <MobileBrocureLink>
            <PdfButton className='narrow' onClick={() => setOpenPopupPdf(true)} />
          </MobileBrocureLink>
          <PdfPopup open={openPopupPdf} onClose={() => setOpenPopupPdf(false)} />
        </>
      )}

      {location.pathname === '/' && isFirstLoad && (
        <HomeIntroAnimation setIsFirstLoad={setIsFirstLoad} />
      )}
      <Header location={location} />
      <div className='home-content'>
        <PageWrapper>
          <GlobalStyle />
          <ContentWrapper>
            <LayoutContent location={location}>{children}</LayoutContent>
          </ContentWrapper>
        </PageWrapper>
        <NavigationPrevNext />
        <Footer />
      </div>
      <FullGallery location={location} />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.object.isRequired
}

export default Layout
