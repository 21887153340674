import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
// import { AnchorLink } from 'gatsby-plugin-anchor-links'
import { calculateResponsiveSize } from 'helpers/calculate-responsive-size'

export const Title = styled.div`
  font-family: 'Two45 Park Display';
  font-style: normal;
  font-weight: 400;
  font-size: ${calculateResponsiveSize(48)};
  line-height: ${calculateResponsiveSize(48)};
  color: var(--lightBeige);
  text-align: center;
  padding: ${calculateResponsiveSize(30)};
  width: 100%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  white-space: break-spaces;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
`
export const ImgWrap = styled.div`
  width: 100%;
  transition: 0.5s;
  .gatsby-image-wrapper {
    height: 100%;
    width: 100%;
  }
`
export const Item = styled((props) => <Link {...props} />)`
  width: ${calculateResponsiveSize(400)};
  display: flex;
  position: relative;
  overflow: hidden;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--gray);
    opacity: 0.5;
    transition: 0.5s;
    mix-blend-mode: multiply;
  }
  &:hover {
    &:after {
      opacity: 0.25;
    }
    ${ImgWrap} {
      transform: scale(1.1);
    }
  }
`
export const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  gap: ${calculateResponsiveSize(12)};
  height: 100%;
  align-items: center;
  position: relative;
  z-index: 2;
  &.mobile {
    flex-direction: column;
    gap: 0;
    height: 100%;
    ${Item} {
      width: 100%;
      align-items: center;
      flex: 1;
    }
    ${Title} {
      font-size: 30px;
      line-height: 30px;
    }
  }
`
