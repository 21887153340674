import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  :root {
    --pureBlack: #1E1E1E;
    --gray: #3F3F3F;
    --lightBeige: #FCF4E8;
    --beige: #E2D1BA;
    --darkBeige: #8B6B44;
    --brown: #8A6B43;
  } 
  li {
    list-style: none;
  }
  * {
    margin: 0;
    padding: 0;
    font-family: inherit;
    box-sizing: border-box;
  }
  body {
    background: var(--lightBeige);
    &.overflow {
      overflow: hidden;
    }
  }
  @-webkit-keyframes mouse-over {
    0% {
      background-size: 0% 100%;
      background-position: 0% 100%;
    }
    100% {
      background-size: 100% 100%;
      background-position: 0% 100%;
    }
  }
  @-webkit-keyframes mouse-out {
    0% {
      background-size: 100% 100%;
      background-position: 100% 0%;
    }
    100% {
      background-size: 0% 100%;
      background-position: 100% 0%;
    }
  }

  .portal {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 103;
    display: flex;
    justify-content: center;
  }
  .gallery-thumbs .swiper-slide{
    opacity: 0.5
  }
  .gallery-thumbs .swiper-slide-thumb-active{
    opacity: 1
  }
  .gallery-swiper.swiper{
    height: 100%;
  }
  .home-wrapper{
    @media (min-width: 769px) {
      .mobile-view{
        display: none;
      }
    }
  }
`

export default GlobalStyle
