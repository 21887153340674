import React from 'react'
import Footer from '..'
import { CloseButton, Wrap, CloseButtonWrap } from './index.styled'

const FooterModal = ({ open, onClose }) => {
  return (
    <Wrap className={open ? 'open' : ''}>
      <CloseButtonWrap onClick={onClose}>
        <CloseButton />
      </CloseButtonWrap>
      <Footer />
    </Wrap>
  )
}

export default FooterModal
