import React, { useState } from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Modal from '../ui/modal'
import Overlay from '../ui/overlay'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper.min.css'

import { FreeMode, Navigation, Thumbs } from 'swiper'
import {
  Container,
  Content,
  Thumb,
  ThumbsList,
  SwiperWrap,
  CloseButton,
  CloseButtonWrap,
  Prev,
  Next,
  Caption,
  ImageWrap,
  SwiperSlideStyled
} from './index.styled'

const Gallery = ({
  nodes = [],
  open = true,
  onClose = () => {},
  imageId,
  fileField = false
}) => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null)
  const navigationPrevRef = React.useRef(null)
  const navigationNextRef = React.useRef(null)

  const index = fileField
    ? nodes.findIndex(({ file: { id } }) => id === imageId)
    : nodes.findIndex(({ id }) => id === imageId)

  const options = {
    loop: true,
    initialSlide: index || 0,
    modules: [Navigation, Thumbs],
    thumbs: { swiper: thumbsSwiper },
    className: 'gallery-swiper'
  }
  return (
    <Modal open={open}>
      <Container>
        <CloseButtonWrap type='button'>
          <CloseButton
            onClick={() => {
              onClose()
              setThumbsSwiper(null)
            }}
          />
        </CloseButtonWrap>

        <Content>
          <SwiperWrap>
            <Swiper
              {...options}
              navigation={{
                prevEl: navigationPrevRef.current,
                nextEl: navigationNextRef.current
              }}
              onBeforeInit={(swiper) => {
                swiper.params.navigation.prevEl = navigationPrevRef.current
                swiper.params.navigation.nextEl = navigationNextRef.current
              }}
            >
              {nodes.map((item) => {
                return (
                  <SwiperSlideStyled key={`gallery-item-${item.id}`}>
                    <ImageWrap>
                      <GatsbyImage
                        image={getImage(
                          fileField
                            ? item?.file?.childImageSharp?.gatsbyImageData
                            : item?.childImageSharp?.gatsbyImageData
                        )}
                        alt={item?.alt || ''}
                        style={{ height: '100%' }}
                        imgStyle={{ objectFit: 'contain' }}
                      />
                    </ImageWrap>
                    <Caption>{item?.caption || /* item?.name || */ ''}</Caption>
                  </SwiperSlideStyled>
                )
              })}
            </Swiper>
          </SwiperWrap>
          <ThumbsList>
            <Swiper
              onSwiper={setThumbsSwiper}
              spaceBetween={24}
              slidesPerView='auto'
              freeMode
              watchSlidesProgress
              modules={[FreeMode, Navigation, Thumbs]}
              className='gallery-thumbs'
            >
              {nodes.map((item) => (
                <SwiperSlide
                  key={`gallery-thumb-${item.id}`}
                  style={{ width: 'auto' }}
                >
                  <Thumb>
                    <GatsbyImage
                      image={getImage(item.thumb)}
                      alt={item?.alt || ''}
                    />
                  </Thumb>
                </SwiperSlide>
              ))}
            </Swiper>
          </ThumbsList>
        </Content>
        <Prev ref={navigationPrevRef} />
        <Next ref={navigationNextRef} />
        <Overlay />
      </Container>
    </Modal>
  )
}

export default Gallery
