import styled from 'styled-components'

export const OverlayDiv = styled.div`
  position: fixed;
  z-index: -101;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: var(--pureBlack);
  &.light {
    background-color: var(--lightBeige);
  }
`
