import styled, { css } from 'styled-components'
import React from 'react'
import { Link } from 'gatsby'
import { mediaMax } from 'helpers/media-queries'
import ArrowIcon from '../../../icons/arrow.svg'

import HeaderLogoMobile from 'icons/mobile-header-logo.svg'

export const LinkStyles = css`
  position: relative;
  font-weight: 700;
  font-family: 'Mark Pro Medium';
  font-size: 12px;
  line-height: 14px;
  padding: 10px 20px;
  color: var(--lightBeige);
  letter-spacing: 0.1em;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  border: 0;
  background: none;
  cursor: pointer;

  &.mobile {
    margin: 0 57px 0 auto;
  }
`
export const NavigationItem = styled((props) => <Link {...props} />)`
  ${LinkStyles}
`

export const MobileHeader = styled.header`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 72px;
  z-index: 100;
  display: none;
  transition-duration: 0s;
  transition-delay: 1s;

  &.open {
    height: 100%;
    transition-delay: 0s;
  }
  ${mediaMax('mobile')} {
    display: block;
  }
`
export const MobileHeaderTop = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 8px 0px;
  background: var(--pureBlack);
  align-items: center;
  border-bottom: 2px solid var(--brown);

  position: relative;
`
export const MobileHeaderTopContent = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  transform: translateX(0);
  transition: 0.5s all;
  padding: 0px 9px;
  &.submenuOpen {
    transform: translateX(-100%);
  }
`
export const MobileHeaderTopSubmenuContent = styled.div`
  position: absolute;
  width: 100%;
  display: flex;
  align-items: center;
  flex: 1;
  transform: translateX(100%);
  transition: 0.5s all;
  padding: 0 14px;
  justify-content: center;
  &.submenuOpen {
    transform: translateX(0);
  }
`

export const MobileHeaderButton = styled.button`
  & {
    background: var(--pureBlack);
    width: 34px;
    height: 34px;
    background: transprent;
    border: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    cursor: pointer;
    //margin-right: 10px;

    position: absolute;
    right: 24px;
  }
  & span {
    display: block;
    position: absolute;

    height: 2px;
    width: 100%;
    background: var(--brown);
    border-radius: 0px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.25s ease-in-out;
    -moz-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;
  }
  span:nth-child(1) {
    top: 11px;
  }
  span:nth-child(2) {
    top: 22px;
  }

  &.open span {
    background: #fff;
  }

  &.open span:nth-child(1) {
    top: 17px;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  &.open span:nth-child(2) {
    top: 17px;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
`

export const MobileHeaderMenu = styled.div`
  display: flex;
  flex-direction: column;
  background: rgba(30, 30, 30, 0.9);
  height: calc(100% - 72px);
  transform: translateX(100%);
  padding: 0 16px;
  transition: 0.5s all;
  transition-delay: 0s;
  overflow: hidden;
  position: relative;
  &.open {
    padding: 0;
    transform: translateX(0);
  }
`
export const MobileHeaderMenuLinks = styled.nav`
  display: flex;
  flex-direction: column;
  transform: translateX(0);
  transition: 0.5s all;

  &.submenuOpen {
    transform: translateX(-100%);
  }
`
export const Submenu = styled.div`
  position: absolute;
  width: 100%;
  transform: translateX(100%);
  transition: 0.5s;
  height: 70%;
  &.submenuOpen {
    transform: translateX(0%);
  }
`

export const MobileHeaderLogo = styled((props) => <Link {...props} />)`
  background-image: url(${HeaderLogoMobile});
  background-repeat: no-repeat;
  background-size: contain;
  width: 86.5px;
  height: 54px;
`

export const LinkStylesMobile = css`
  font-family: 'Two45 Park Display';
  padding: 24px;
  color: #fff;
  font-weight: 300;
  font-size: 30px;
  line-height: 30px;
  border-bottom: 2px solid var(--brown);
  text-decoration: none;
  text-transform: uppercase;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:last-child {
    border-bottom: none;
  }
`
export const MobileHeaderMenuItemExternal = styled.a`
  ${LinkStylesMobile}
`

export const MobileHeaderMenuItem = styled((props) => <Link {...props} />)`
  ${LinkStylesMobile}
`

export const HeaderMobileFooter = styled.div`
  height: 30%;
  margin-top: auto;
  background: var(--pureBlack);
  border-top: 2px solid var(--brown);
  padding: 24px;
  display: flex;
  flex-direction: column;
`
export const SocialMobile = styled.div`
  flex: 1;
  transform: translateY(30%);
`
export const SubmenuArrow = styled.div`
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 21px;
  height: 30px;
  background-image: url(${ArrowIcon});
  transform: rotate(-90deg);
  cursor: pointer;
  &.back {
    transform: rotate(90deg);
    position: absolute;
    left: 24px;
  }
`
export const SubmenuTitle = styled.div`
  font-family: 'Two45 Park Display';
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 36px;
  color: var(--brown);
  text-transform: uppercase;
`
export const Contact = styled.div`
  position: fixed;
  height: calc(100% - 72px);
  opacity: 0;
  transform: translateX(-100%);
  transition: opacity 1s ease 0s, transform 0s ease 1s;
  &.open {
    position: fixed;
    overflow: auto;
    opacity: 1;
    transform: translateX(0);
    transition: opacity 1s ease 0s, transform 0s ease 0s;
  }
`
export const PrivacyWrap = styled.div`
  display: flex;
  margin-top: auto;
  flex-direction: column;
  gap: 24px;
`
