import styled from 'styled-components'
import { calculateResponsiveSize } from 'helpers/calculate-responsive-size'
import { mediaMax } from 'helpers/media-queries'
import CloseIcon from '../../../icons/close.svg'

export const PopupWrapper = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  width: 100%;
  max-width: ${calculateResponsiveSize(336)};
  padding: ${calculateResponsiveSize(20)} ${calculateResponsiveSize(20)} ${calculateResponsiveSize(26)};
  color: #FCF4E8;
  background: #8B6B44;
  transform: translateY(-120%);
  visibility: hidden;
  transition: 0.5s;
  z-index: -2;
  &.open {
    transform: translateY(0);
    visibility: visible;
  }
  ${mediaMax('mobile')} {
    top: 50%;
    right: 50%;
    max-width: 336px;
    padding: 20px 20px 26px;
    opacity: 0;
    transform: translate(50%, -50%);
    z-index: 3;
    &.open {
      opacity: 1;
      transform: translate(50%, -50%);
    }
  }
`

export const CloseButtonWrap = styled.button`
  position: absolute;
  right: ${calculateResponsiveSize(20)};
  top: ${calculateResponsiveSize(20)};
  border: none;
  background: transparent;
  appearance: none;
  cursor: pointer;
  ${mediaMax('mobile')} {
    right: 20px;
    top: 20px;
  }
`

export const CloseButton = styled.div`
  width: ${calculateResponsiveSize(36)};
  height: ${calculateResponsiveSize(36)};
  background-image: url(${CloseIcon});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  ${mediaMax('mobile')} {
    width: 36px;
    height: 36px;
  }
`

export const Title = styled.div`
  margin-bottom: ${calculateResponsiveSize(40)};
  font-family: 'Two45 Park Display';
  font-size: ${calculateResponsiveSize(36)};
  line-height: 1;
  letter-spacing: 3px;
  text-transform: uppercase;
  ${mediaMax('mobile')} {
    margin-bottom: 40px;
    font-size: 36px;
  }
`

export const Button = styled.button`
  width: 100%;
  border: none;
  background: transparent;
  appearance: none;
`

export const ErrorMessage = styled.div`
  position: absolute;
  right: ${calculateResponsiveSize(20)};
  font-family: 'GT Super Text';
  font-size: ${calculateResponsiveSize(14)};
  font-style: italic;
  font-weight: 300;
  line-height: 1;
  transform: translateY(${calculateResponsiveSize(-30)});
  ${mediaMax('mobile')} {
    right: 20px;
    font-size: 14px;
    transform: translateY(-30px);
  }
`

export const ThanksMessage = styled.div`
  font-family: 'Two45 Park Display';
  font-size: ${calculateResponsiveSize(20)};
  line-height: 1.25;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  ${mediaMax('mobile')} {
    font-size: 20px;
  }
`

export const Overlay = styled.div`
  display: none;
  ${mediaMax('mobile')} {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.50);
    visibility: hidden;
    opacity: 0;
    transition: 0.5s;
    z-index: 2;
    &.open {
      display: block;
      visibility: visible;
      opacity: 1;
    }
  }
`
