import styled, { css } from 'styled-components'
import { calculateResponsiveSize } from 'helpers/calculate-responsive-size'
import { mediaMax } from 'helpers/media-queries'

export const Lotti = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
  padding: 0 25%;

  background: var(--pureBlack);
  .lf-player-container {
    width: 100%;
    height: 100%;
  }
  #lottie {
    height: 100%;
  }
  transition: 1s;
  ${(props) =>
    props.isFinish === true &&
    css`
      transform: translateY(-100%);
    `}
  ${mediaMax('mobile')} {
    padding: 0 10%;
  }
`
export const ImgWrap = styled.div`
  transform: translateY(-36px) scale(1.1);
  transition: 3s;
`
export const BgImg = styled.div`
  background: var(--lightBeige);
  height: 100%;
  position: fixed;
  width: 100%;
  top: 0;
  transition: 1s;
  overflow: hidden;

  &.hide {
    transform: translateY(-100%);
  }
  &.animated ${ImgWrap} {
    transform: translateY(36px) scale(1.1);
  }
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #1e1e1e;
    mix-blend-mode: multiply;
    opacity: 0.15;
  }
`

export const Text = styled.div`
  font-family: 'Mark Pro Medium';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 15px;
  letter-spacing: 0.1em;
  color: var(--lightBeige);
  position: absolute;
  bottom: ${calculateResponsiveSize(36)};
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  ${mediaMax('mobile')} {
    font-size: 14px;
    line-height: 14px;
    bottom: 48px;
  }
`
export const Container = styled.div`
  position: relative;
  z-index: 105;
  overflow: hidden;
`
