import styled from 'styled-components'
import { calculateResponsiveSize } from 'helpers/calculate-responsive-size'
import CloseIcon from '../../../icons/close.svg'

export const Wrap = styled.div`
  position: fixed;
  width: 100%;
  z-index: 10;
  bottom: 0;
  transform: translateY(100%);
  transition: 1s;
  &.open {
    transform: translateY(0%);
  }
`
export const CloseButtonWrap = styled.button`
  position: absolute;
  right: ${calculateResponsiveSize(36)};
  top: ${calculateResponsiveSize(36)};
  cursor: pointer;
  background: transparent;
  appearance: none;
  border: none;
`

export const CloseButton = styled.div`
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  width: ${calculateResponsiveSize(36)};
  height: ${calculateResponsiveSize(36)};
  background-image: url(${CloseIcon});
`
