import React from 'react'
import { Link } from 'gatsby-link'
import styled, { css } from 'styled-components'
import { calculateResponsiveSize } from 'helpers/calculate-responsive-size'
import { mediaMax } from 'helpers/media-queries'

import ArrowIcon from 'icons/arrow.svg'

export const Container = styled.div`
  background: var(--gray);
  padding: ${calculateResponsiveSize(10)} ${calculateResponsiveSize(72)};
  display: flex;
  justify-content: space-between;
  ${mediaMax('mobile')} {
    padding: 8px 24px;
  }
`
export const Item = css`
  font-family: 'Mark Pro Medium';
  font-style: normal;
  font-weight: 500;
  font-size: ${calculateResponsiveSize(15)};
  line-height: ${calculateResponsiveSize(15)};
  color: var(--lightBeige);
  text-decoration: none;
  display: flex;
  align-items: center;
  letter-spacing: 0.1em;
  gap: ${calculateResponsiveSize(6)};
  text-transform: uppercase;
  transition: 0.5s;

  &:before {
    content: '';
    background: var(--lightBeige);
    mask-position: center center;
    mask-repeat: no-repeat;
    mask-size: contain;
    width: ${calculateResponsiveSize(13)};
    height: ${calculateResponsiveSize(13)};
    mask-image: url(${ArrowIcon});
    display: block;
    transform: rotate(90deg);
  }
  &:hover {
    opacity: 0.5;
  }
  ${mediaMax('mobile')} {
    font-size: 14px;
    line-height: 15px;
    gap: 6px;

    &:before {
      width: 13px;
      height: 13px;
    }
  }
`
export const Left = styled((props) => <Link {...props} />)`
  ${Item}
`
export const Right = styled((props) => <Link {...props} />)`
  ${Item}
  flex-direction: row-reverse;
  &:before {
    transform: rotate(-90deg);
  }
`
