import React from 'react'
import { Container, Title, Nubmer } from './index.styled'

const LinksSubMenu = ({ items, isMobile = false, onClick = () => {} }) => {
  return (
    <Container className={isMobile && 'mobile'}>
      {items.map(({ text, url }, i) => {
        return (
          <Title key={`submenu-${text}-${i}`} to={url} onClick={onClick}>
            <Nubmer>{(i + 1).toString().padStart(2, '0')}</Nubmer>
            {text}
          </Title>
        )
      })}
    </Container>
  )
}

export default LinksSubMenu
