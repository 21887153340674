import React from 'react'
import styled, { css } from 'styled-components'
import { Link } from 'gatsby'
// import { AnchorLink } from 'gatsby-plugin-anchor-links'
import { calculateResponsiveSize } from 'helpers/calculate-responsive-size'
import { mediaMax } from 'helpers/media-queries'
import LiSubmenuArrow from 'icons/li-submenu.svg'

import HeaderLogo from 'icons/header-logo.svg'
import HeaderLogoWhite from 'icons/header-logo-white.svg'
import ArrowIcon from 'icons/arrow.svg'

export const HeaderWrapper = styled.header`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: var(--pureBlack);
  transition: all 2s;
  z-index: 100;
  height: ${calculateResponsiveSize(72)};
  border-bottom: 2px solid var(--brown);
  ${mediaMax('mobile')} {
    display: none;
  }
`

export const Navigation = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${calculateResponsiveSize(0)} ${calculateResponsiveSize(36)};
  position: relative;
  &::before {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 25%;
    content: "";
    background: var(--pureBlack);
    border-bottom: 1px solid var(--brown);
    z-index: 1;
  }
`
export const RightButtons = styled.div`
  display: flex;
  gap: ${calculateResponsiveSize(12)};
  position: relative;
  z-index: 2;
`
export const Logo = styled((props) => <Link {...props} />)`
  width: ${calculateResponsiveSize(397)};
  height: ${calculateResponsiveSize(30)};
  background: url(${HeaderLogo}) no-repeat;
  background-size: contain;
  transition: 0.5s;
  &:hover {
    background: url(${HeaderLogoWhite}) no-repeat;
    background-size: contain;
  }
`
export const Submenu = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  left: 0;
  height: 0px;
  width: 100%;
  position: absolute;
  top: ${calculateResponsiveSize(72)};
  overflow: hidden;
  transition: 1s;
  z-index: 1;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--pureBlack);
    opacity: 0.9;
  }
`
export const SubmenuContent = styled.div`
  flex-direction: column;
  align-items: center;
  display: flex;
  height: calc(100% - ${calculateResponsiveSize(36)});
`

export const LinkStyles = css`
  position: relative;
  font-weight: 700;
  font-family: 'Mark Pro Medium';
  letter-spacing: ${calculateResponsiveSize(2)};
  font-size: ${calculateResponsiveSize(16)};
  line-height: ${calculateResponsiveSize(14)};
  padding: ${calculateResponsiveSize(10)} ${calculateResponsiveSize(10)};
  color: var(--lightBeige);
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  border: 0;
  background: none;
  cursor: pointer;
`
export const NavigationItem = styled((props) => <Link {...props} />)`
  ${LinkStyles}
`

export const NavigationLi = styled.li`
  height: ${calculateResponsiveSize(72)};
  cursor: pointer;
  display: flex;
  align-items: center;

  &.submenu {
    margin-right: ${calculateResponsiveSize(10)};
    padding-right: ${calculateResponsiveSize(10)};

    & ${NavigationItem} {
      padding-right: ${calculateResponsiveSize(20)};

      &:after {
        content: '';
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        width: ${calculateResponsiveSize(12)};
        height: ${calculateResponsiveSize(6)};
        mask: url(${LiSubmenuArrow}) no-repeat;
        mask-size: cover;
        transition: 0.5s;
        background: var(--lightBeige);
      }
    }
  }
  &.active {
    ${NavigationItem} {
      color: var(--darkBeige);
      &:after {
        background: var(--darkBeige);
      }
    }
  }
  &:hover {
    ${NavigationItem} {
      color: var(--darkBeige);
      &:after {
        transform: translateY(-50%) rotate(180deg);
        background: var(--darkBeige);
      }
    }
    ${Submenu} {
      height: ${calculateResponsiveSize(300)};
      cursor: default;
      &.closeHover {
        height: 0px;
      }
    }
  }
`

export const NavigationLinksWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  gap: ${calculateResponsiveSize(40)};
`
export const HoverHighlight = styled.div`
  width: 100px;
  height: 2px;
  background: var(--lightBeige);
  position: absolute;
  bottom: -2px;
  transform: translateX(-100%);

  transition: 1s;
`
export const CloseArrow = styled.div`
  z-index: 10;
  background-image: url(${ArrowIcon});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;

  height: ${calculateResponsiveSize(24)};
  width: ${calculateResponsiveSize(21)};
  margin-bottom: ${calculateResponsiveSize(12)};

  position: absolute;
  bottom: ${calculateResponsiveSize(12)};
  left: 50%;
  transform: translateX(-50%) rotate(-180deg);
  cursor: pointer;
`
