import React from 'react'

import { Element, Icon } from './index.styled'

const PdfButton = ({ title = 'Brochure', ...props }) => {
  return (
    <Element {...props} target='_blank'>
      <Icon />
      {title}
    </Element>
  )
}

export default PdfButton
