import React, { useEffect } from 'react'
import Social from '../../footer/social'
import ImagesSubMenu from '../images-submenu'
import LinksSubMenu from '../links-submenu'
import Footer from '../../footer'
import Privacy from '../../footer/privacy'
import { graphql, useStaticQuery } from 'gatsby'
import {
  NavigationItem,
  MobileHeader,
  MobileHeaderButton,
  MobileHeaderTop,
  MobileHeaderTopContent,
  MobileHeaderTopSubmenuContent,
  MobileHeaderMenu,
  MobileHeaderLogo,
  MobileHeaderMenuItem,
  MobileHeaderMenuItemExternal,
  HeaderMobileFooter,
  MobileHeaderMenuLinks,
  SocialMobile,
  Submenu,
  SubmenuArrow,
  SubmenuTitle,
  Contact,
  PrivacyWrap
} from './index.styled'

const HeaderMobile = () => {
  const {
    menuContentMob: { content: links }
  } = useStaticQuery(graphql`
    query MenuMobQuery {
      menuContentMob {
        content {
          text
          url
          submenu {
            type
            list {
              url
              text
              image {
                file {
                  publicURL
                  childImageSharp {
                    gatsbyImageData(
                      width: 400
                      height: 200
                      transformOptions: { cropFocus: CENTER }
                    )
                  }
                }
              }
              mobileImage: image {
                file {
                  publicURL
                  childImageSharp {
                    gatsbyImageData(transformOptions: { cropFocus: CENTER })
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
  const [isMobMenu, setIsMobMenu] = React.useState(false)
  const [submenuLinks, setSubmenuLinks] = React.useState(null)
  const [submenuTitle, setSubmenuTitle] = React.useState('')

  const [isSubMenu, setIsSubMenu] = React.useState(false)

  const [isContact, setIsContact] = React.useState(false)
  const [isBackState, setIsBackState] = React.useState(false)

  const onClose = () => {
    if (isContact) {
      setIsContact(false)
    } else {
      setIsMobMenu(!isMobMenu)
      setSubmenuLinks(null)
      setIsSubMenu(false)
    }
    setIsBackState(false)
  }
  const onBack = () => {
    setIsSubMenu(false)
  }

  useEffect(() => {
    document.body.style.overflow = isContact || isMobMenu ? 'hidden' : 'initial'
  }, [isContact, isMobMenu])

  useEffect(() => {
    if (isBackState) {
      setIsContact(true)
      setIsMobMenu(false)
    }
  }, [isBackState])

  return (
    <>
      <MobileHeader className={[isMobMenu ? 'open ' : ' ']} id='headerMob'>
        <MobileHeaderTop id='menu'>
          <MobileHeaderTopContent className={[isSubMenu && 'submenuOpen']}>
            <MobileHeaderLogo to='/' onClick={() => setIsMobMenu(false)} />
            <NavigationItem
              to='/'
              as='button'
              onClick={() => {
                if (isBackState) {
                  setIsContact(false)
                  setIsMobMenu(true)
                  setIsBackState(false)
                } else if (isMobMenu) setIsBackState(true)
                else setIsContact(true)
              }}
              className='mobile'
            >
              {isBackState ? 'Back' : 'Contact'}
            </NavigationItem>
          </MobileHeaderTopContent>
          <MobileHeaderTopSubmenuContent
            className={[isSubMenu && 'submenuOpen']}
          >
            <SubmenuArrow className='back' onClick={onBack} />
            <SubmenuTitle>{submenuTitle}</SubmenuTitle>
          </MobileHeaderTopSubmenuContent>

          <MobileHeaderButton
            onClick={onClose}
            className={[isMobMenu || isContact ? 'open ' : '']}
          >
            <span />
            <span />
          </MobileHeaderButton>
        </MobileHeaderTop>
        <Contact className={isContact && 'open'}>
          <Footer className='mobileContact' />
        </Contact>
        <MobileHeaderMenu className={[isMobMenu ? 'open ' : '']}>
          <MobileHeaderMenuLinks className={[isSubMenu && 'submenuOpen']}>
            {links.map((item, index) => (
              <React.Fragment key={`header-item-mobile-${index}`}>
                {item.external === true
                  ? (
                    <MobileHeaderMenuItemExternal href={item.url} target='_blank'>
                      {item.text}
                    </MobileHeaderMenuItemExternal>
                    )
                  : item?.submenu
                    ? (
                      <MobileHeaderMenuItem
                        to={item.url}
                        onClick={(e) => {
                          e.preventDefault()
                          setSubmenuTitle(item.text)
                          setSubmenuLinks(item?.submenu)
                          setIsSubMenu(true)
                        }}
                      >
                        {item.text}
                        <SubmenuArrow />
                      </MobileHeaderMenuItem>
                      )
                    : (
                      <MobileHeaderMenuItem
                        to={item.url}
                        onClick={() => setIsMobMenu(false)}
                      >
                        {item.text}
                      </MobileHeaderMenuItem>
                      )}
              </React.Fragment>
            ))}
            <MobileHeaderMenuItem
              to='/sl-green'
              onClick={() => setIsMobMenu(false)}
            >
              SL Green
            </MobileHeaderMenuItem>
          </MobileHeaderMenuLinks>

          <Submenu className={[isSubMenu && 'submenuOpen']}>
            {submenuLinks?.type === 'images'
              ? (
                <ImagesSubMenu
                  items={submenuLinks?.list || []}
                  isMobile
                  onClick={onClose}
                />
                )
              : (
                <LinksSubMenu
                  items={submenuLinks?.list || []}
                  isMobile
                  onClick={onClose}
                />
                )}
          </Submenu>
          <HeaderMobileFooter>
            <SocialMobile>
              <Social />
            </SocialMobile>
            <PrivacyWrap>
              <Privacy />
            </PrivacyWrap>
          </HeaderMobileFooter>
        </MobileHeaderMenu>
      </MobileHeader>
    </>
  )
}

export default HeaderMobile
