import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'

const ModalContainer = ({ children, el = 'div' }) => {
  const [container] = useState(() => {
    return document.createElement(el)
  })

  useEffect(() => {
    container.classList.add('portal')
    const parentElement = document.body

    if (!parentElement) {
      return
    }

    parentElement.appendChild(container)
    parentElement.style.overflow = 'hidden'
    return () => {
      parentElement.style.overflow = 'initial'
      parentElement.removeChild(container)
    }
  }, [container])

  return ReactDOM.createPortal(children, container)
}

const Modal = ({ open, children, ...props }) =>
  open ? <ModalContainer {...props}> {children} </ModalContainer> : null

export default Modal
