import React, { useMemo } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
// import PdfButton from '../ui/pdf-button'
import { StaticImage } from 'gatsby-plugin-image'
import useWindowSize from '../../helpers/use-window-size'

import Social from './social'
import Privacy from './privacy'
import {
  FooterWrapper,
  Left,
  Right,
  Title,
  Logos,
  LogosItem,
  Users,
  UsersMain,
  UsersSecondary,
  User,
  Logo,
  Name,
  Email,
  Phone,
  Links,
  LinkItem,
  ExternalLink,
  Bottom,
  Copyright,
  Address,
  OtherLinks
} from './index.styled'

const Footer = ({ className }) => {
  const { isMobile } = useWindowSize()
  const currentYear = useMemo(() => new Date().getFullYear(), [])

  const {
    dataJson: {
      footerInfo: { title, users, links, copyright1, copyright2, address }
    }
  } = useStaticQuery(graphql`
    query {
      dataJson {
        footerInfo {
          title
          pdgLink
          address
          users {
            name
            position
            phone
            email
          }
          links {
            title
            link
            external
          }
          secondaryLinks {
            title
            link
            external
          }
          copyright1
          copyright2
        }
      }
    }
  `)

  return (
    <FooterWrapper className={className}>
      <Left>
        <Title>{title}</Title>
        {/* <PdfButton /> */}
        {!isMobile && (
          <>
            <Logo>
              <StaticImage
                alt='245 Park Avenue'
                src='../../icons/logoVertical.svg'
              />
            </Logo>
            <Address>{address}</Address>
          </>
        )}
      </Left>
      <Right>
        <Logos>
          <LogosItem>
            <StaticImage alt='SL Green' src='../../icons/slgreenLogo.svg' />
          </LogosItem>
          {!isMobile && (
            <LogosItem>
              <StaticImage
                alt='wakefieldLogo'
                src='../../icons/wakefieldLogo.svg'
              />
            </LogosItem>
          )}
        </Logos>
        <Users>
          <UsersMain>
            {users.slice(0, 3).map((user, i) => (
              <React.Fragment key={`footer-user-${i}`}>
                <User>
                  <Name>{user.name}</Name>
                  <div>{user.position}</div>
                  <Phone href={`tel:${user.phone}`}>{user.phone}</Phone>
                  <Email href={`mailto:${user.email}`}>{user.email}</Email>
                </User>
                {i === 2 && isMobile && (
                  <Logos>
                    <LogosItem>
                      <StaticImage
                        alt='wakefieldLogo'
                        src='../../icons/wakefieldLogo.svg'
                      />
                    </LogosItem>
                  </Logos>
                )}
              </React.Fragment>
            ))}
          </UsersMain>
          <UsersSecondary>
            {users.slice(3).map((user, i) => (
              <React.Fragment key={`footer-user-${i}`}>
                <User>
                  <Name>{user.name}</Name>
                  <div>{user.position}</div>
                  <Phone href={`tel:${user.phone}`}>{user.phone}</Phone>
                  <Email href={`mailto:${user.email}`}>{user.email}</Email>
                </User>
              </React.Fragment>
            ))}
          </UsersSecondary>
        </Users>

        <Links>
          <OtherLinks>
            {links.map((l, i) =>
              l?.external === true
                ? (
                  <ExternalLink key={`links-${i}`} href={l.link} target='_blank'>
                    {l.title}
                  </ExternalLink>
                  )
                : (
                  <LinkItem key={`links-${i}`} to={l.link}>
                    {l.title}
                  </LinkItem>
                  )
            )}
          </OtherLinks>
          <Social />
        </Links>
        <Bottom>
          <Privacy />
          <Copyright>{copyright1} © {currentYear}. <span>{copyright2}</span></Copyright>
        </Bottom>
        {isMobile && (
          <>
            <Logo>
              <StaticImage
                alt='245 Park Avenue'
                src='../../icons/logoVertical.svg'
              />
            </Logo>
            <Address>{address}</Address>
          </>
        )}
      </Right>
    </FooterWrapper>
  )
}

export default Footer
