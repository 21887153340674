import React from 'react'
import { FormProvider } from 'react-hook-form'

const Form = ({ children, onSubmit, formProps, className = '' }) => {
  return (
    <FormProvider {...formProps}>
      <form
        noValidate
        onSubmit={formProps.handleSubmit(onSubmit)}
        className={className}
      >
        {children}
      </form>
    </FormProvider>
  )
}
export default Form
