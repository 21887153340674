import styled from 'styled-components'
import { calculateResponsiveSize } from 'helpers/calculate-responsive-size'
import { mediaMax } from 'helpers/media-queries'

export const Title = styled.div`
  margin-bottom: ${calculateResponsiveSize(20)};
  font-family: 'Mark Pro Medium';
  font-size: ${calculateResponsiveSize(14)};
  color: #FCF4E8;
  font-weight: 500;
  line-height: 1;
  letter-spacing: ${calculateResponsiveSize(1.4)};
  text-transform: uppercase;
  ${mediaMax('mobile')} {
    margin-bottom: 20px;
    font-size: 14px;
    letter-spacing: 1.4px;
  }
`
export const InputField = styled.input`
  width: 100%;
  height: ${calculateResponsiveSize(26)};
  margin-bottom: ${calculateResponsiveSize(40)};
  padding: 0 0 ${calculateResponsiveSize(10)} 0;
  font-family: 'GT Super Text';
  font-size: ${calculateResponsiveSize(16)};
  color: #FCF4E8;
  font-weight: 300;
  line-height: normal;
  border: none;
  border-bottom: 1px solid #FCF4E8;
  border-radius: 0;
  background: none;
  outline: none;
  -webkit-appearance: none;
  /* &.error {
    border-color: #cd3b34;
  } */
  ${mediaMax('mobile')} {
    height: 26px;
    margin-bottom: 40px;
    padding: 0 0 10px 0;
    font-size: 16px;
  }
`
