import React, { useRef, useState, useEffect } from 'react'
import { Player } from '@lottiefiles/react-lottie-player'
import { StaticImage } from 'gatsby-plugin-image'
import data from 'static/intro_animation.json'
import dataMob from 'static/intro_animation_mobile.json'
import useWindowSize from '../../../helpers/use-window-size'

import { Lotti, BgImg, Container, Text, ImgWrap } from './index.styled'

const HomeIntroAnimation = ({ setIsFirstLoad = () => {} }) => {
  const [isFinish, seIsFinish] = useState(false)
  const { isMobile } = useWindowSize()
  const ref = useRef()

  const onScroll = () => {
    ref?.current?.classList?.add('hide')
    document.body.style.overflow = 'initial'
    setTimeout(() => {
      setIsFirstLoad(false)
    }, 3000)
  }

  const onFinish = () => {
    seIsFinish(true)
    ref.current.classList.add('animated')
    ref.current.addEventListener('scroll', onScroll)
    ref.current.addEventListener('touchmove', onScroll)
    window.addEventListener('scroll', onScroll)
  }

  useEffect(() => {
    document.body.style.overflow = 'hidden'

    return () => {
      ref?.current?.removeEventListener('scroll', onScroll, false)
      ref?.current?.removeEventListener('touchmove', onScroll, false)
      window?.removeEventListener('scroll', onScroll, false)
    }
  }, [])

  return (
    <Container>
      <Lotti isFinish={isFinish}>
        <Player
          onEvent={(event) => {
            if (event === 'complete') onFinish()
          }}
          keepLastFrame
          autoplay
          src={!isMobile ? data : dataMob}
        />
      </Lotti>
      <BgImg className='intro-img' ref={ref}>
        <ImgWrap>
          <StaticImage
            alt=''
            src='../../../images/home-start.jpg'
            imgStyle={{ height: '100vh' }}
            style={{ height: '100vh' }}
          />
        </ImgWrap>
        <Text>SCROLL TO BEGIN</Text>
      </BgImg>
    </Container>
  )
}

export default HomeIntroAnimation
