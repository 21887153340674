import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { LinkItem, ExternalLink } from './index.styled'

const Privacy = () => {
  const {
    dataJson: {
      footerInfo: { secondaryLinks }
    }
  } = useStaticQuery(graphql`
    query {
      dataJson {
        footerInfo {
          secondaryLinks {
            title
            link
            external
          }
        }
      }
    }
  `)

  return secondaryLinks.map((l, i) => {
    return l?.external === true
      ? (
        <ExternalLink
          key={`links-secondary-${i}`}
          href={l.link}
          target='_blank'
          className='small'
        >
          {l.title}
        </ExternalLink>
        )
      : (
        <LinkItem key={`links-secondary-${i}`} to={l.link} className='small'>
          {l.title}
        </LinkItem>
        )
  })
}

export default Privacy
