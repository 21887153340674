import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Container, ImgWrap, Item, Title } from './index.styled'

const ImagesSubMenu = ({ items, isMobile = false, onClick = () => {} }) => {
  return (
    <Container className={isMobile && 'mobile'}>
      {items.map(({ image, mobileImage, text, url }, i) => (
        <Item key={`submenu-${text}-${i}`} to={url} onClick={onClick}>
          <ImgWrap>
            {isMobile
              ? (
                <GatsbyImage image={getImage(mobileImage?.file)} alt='' />
                )
              : (
                <GatsbyImage image={getImage(image?.file)} alt='' />
                )}
          </ImgWrap>
          <Title>{text}</Title>
        </Item>
      ))}
    </Container>
  )
}

export default ImagesSubMenu
