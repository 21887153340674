import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { LinkItem, Icon, SocialWrap } from './index.styled'

const Social = () => {
  const {
    dataJson: {
      footerInfo: { socialLinks }
    }
  } = useStaticQuery(graphql`
    query {
      dataJson {
        footerInfo {
          socialLinks {
            type
            link
          }
        }
      }
    }
  `)

  return (
    <SocialWrap>
      {socialLinks.map((l, i) => {
        return (
          <LinkItem key={`links-social-${i}`} to={l.link} target='_blank'>
            <Icon className={l.type} />
          </LinkItem>
        )
      })}
    </SocialWrap>
  )
}

export default Social
