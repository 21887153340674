import React from 'react'
import styled from 'styled-components'
import { calculateResponsiveSize } from 'helpers/calculate-responsive-size'
import { mediaMax } from 'helpers/media-queries'
import CloseIcon from '../../icons/close.svg'
import ArrowIcon from '../../icons/leftArrow.svg'
import { SwiperSlide } from 'swiper/react'

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
export const Content = styled.div`
  width: 100%;
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: ${calculateResponsiveSize(50)} ${calculateResponsiveSize(122)};
  ${mediaMax('mobile')} {
    padding: 24px;
  }
`
export const Thumb = styled.div`
  width: ${calculateResponsiveSize(126)};
  height: ${calculateResponsiveSize(126)};
  cursor: pointer;
  ${mediaMax('mobile')} {
    width: 107px;
    height: 107px;
  }
`
export const ThumbsList = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${calculateResponsiveSize(24)};
  ${mediaMax('mobile')} {
    margin-top: 24px;
  }
`
export const SwiperWrap = styled.div`
  height: calc(100% - ${calculateResponsiveSize(186)});
  display: flex;
  align-items: center;
  ${mediaMax('mobile')} {
    height: calc(100% - 133px);
  }
`

export const Next = styled.div`
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  width: ${calculateResponsiveSize(18)};
  height: ${calculateResponsiveSize(46)};
  background-image: url(${ArrowIcon});
  cursor: pointer;
  transform: rotate(180deg);

  position: absolute;
  right: ${calculateResponsiveSize(32)};
  ${mediaMax('mobile')} {
    width: 7px;
    height: 22px;
    right: 10px;
    top: calc(50% - 85px);
  }
`
export const Prev = styled.div`
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  width: ${calculateResponsiveSize(18)};
  height: ${calculateResponsiveSize(46)};
  background-image: url(${ArrowIcon});
  cursor: pointer;
  position: absolute;
  left: ${calculateResponsiveSize(32)};
  ${mediaMax('mobile')} {
    width: 7px;
    height: 22px;
    left: 10px;
    top: calc(50% - 85px);
  }
`
export const CloseButtonWrap = styled.button`
  position: absolute;
  right: ${calculateResponsiveSize(36)};
  top: ${calculateResponsiveSize(36)};
  cursor: pointer;
  background: transparent;
  appearance: none;
  border: none;
  ${mediaMax('mobile')} {
    right: 24px;
    top: 24px;
    z-index: 10;
  }
`

export const CloseButton = styled.div`
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  width: ${calculateResponsiveSize(36)};
  height: ${calculateResponsiveSize(36)};
  background-image: url(${CloseIcon});
  ${mediaMax('mobile')} {
    width: 24px;
    height: 24px;
  }
`
export const Caption = styled.div`
  margin-top: ${calculateResponsiveSize(11)};
  font-family: 'Mark Pro';
  font-style: italic;
  font-weight: 350;
  font-size: ${calculateResponsiveSize(18)};
  line-height: ${calculateResponsiveSize(24)};
  color: var(--lightBeige);
  text-align: center;
  ${mediaMax('mobile')} {
    font-size: 14px;
    line-height: 18px;
    margin-top: 11px;
  }
`
export const ImageWrap = styled.div`
  overflow: hidden;
`
export const SwiperSlideStyled = styled((props) => <SwiperSlide {...props} />)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
