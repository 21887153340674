import React from 'react'
import styled from 'styled-components'
import { calculateResponsiveSize } from 'helpers/calculate-responsive-size'
import { mediaMax } from 'helpers/media-queries'
import { Link } from 'gatsby'

export const Element = styled((props) => <Link {...props} />)`
  border: 1px solid var(--brown);
  font-family: 'Mark Pro Medium';
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.1em;
  font-size: ${calculateResponsiveSize(14)};
  line-height: ${calculateResponsiveSize(14)};
  text-transform: uppercase;
  padding: ${calculateResponsiveSize(11)} ${calculateResponsiveSize(24)};
  cursor: pointer;
  text-decoration: none;

  background-image: linear-gradient(var(--brown), var(--brown));
  background-position: 0% 100%;
  background-repeat: no-repeat;
  background-size: 0% 100%;
  background-color: transparent;
  transition: background-size 0s ease 0s, color 0.5s;

  color: var(--brown);
  &:visited {
    color: currentColor;
  }
  &.white {
    color: #fff;
  }
  &:hover {
    background-size: 100% 100%;
    transition: background-size 0s ease 0.5s, color 0.5s;
    color: #fff;
  }
  &.mouseOver {
    animation-name: mouse-over;
    animation-duration: 0.5s;
  }
  &.mouseOut {
    animation-name: mouse-out;
    animation-duration: 0.5s;
  }
  ${mediaMax('mobile')} {
    font-size: 14px;
    line-height: 14px;
    padding: 11px 28px;
    background-size: 100% 100%;
    color: #fff;
    &:visited {
      color: #fff;
    }
  }
`
