import styled from 'styled-components'
import { calculateResponsiveSize } from 'helpers/calculate-responsive-size'
import { mediaMax } from 'helpers/media-queries'
import PdfIcon from '../../../icons/download.svg'

export const Icon = styled.span`
  background-color: var(--lightBeige);
  mask-position: center center;
  mask-repeat: no-repeat;
  mask-size: contain;
  width: ${calculateResponsiveSize(10)};
  height: ${calculateResponsiveSize(10)};
  mask-image: url(${PdfIcon});
  display: block;
  transition: 0.5s;
  ${mediaMax('mobile')} {
    width: 10px;
    height: 10px;
  }
`
export const Element = styled.a`
  background-color: var(--brown);
  color: var(--lightBeige);
  font-family: 'Mark Pro Medium';
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.1em;
  font-size: ${calculateResponsiveSize(14)};
  line-height: ${calculateResponsiveSize(14)};
  text-transform: uppercase;
  padding: ${calculateResponsiveSize(11)} ${calculateResponsiveSize(24)};
  cursor: pointer;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 6px;
  transition: 0.5s;
  &.popup-download {
    justify-content: center;
    border: 1px solid #FCF4E8;
  }

  &:visited {
    color: var(--lightBeige);
  }
  &:hover {
    background-color: var(--lightBeige);
    color: var(--brown);
    & ${Icon} {
      background-color: var(--brown);
    }
  }
  ${mediaMax('mobile')} {
    font-size: 14px;
    line-height: 16px;
    padding: 11px 24px;
    &.narrow {
      padding: 4px 24px;
      ${Icon} {
        transform: rotate(90deg);
      }
    }
  }
`
